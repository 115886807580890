import { AUTH0_DOMAIN } from '../../env'

const continueLogin = () => {
  const state = new URLSearchParams(window.location.search).get('state')
  return `https://${AUTH0_DOMAIN}/continue?state=${state}`
}

const resumeLogin = () => {
  const state = new URLSearchParams(window.location.search).get('state')
  return `https://${AUTH0_DOMAIN}/u/login/identifier?state=${state}`
}

const forgotEmail = () => {
  const state = new URLSearchParams(window.location.search).get('state')
  return `${window.location.origin}/forgot-email?state=${state}`
}

const passwordResetStart = email => {
  const state = new URLSearchParams(window.location.search).get('state')
  return `https://${AUTH0_DOMAIN}/u/login/password-reset-start/username-password/${encodeURIComponent(
    email
  )}?state=${state}`
}

export { continueLogin, resumeLogin, forgotEmail, passwordResetStart }

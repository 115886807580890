import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { sections } from '../../lib/membership'
import { API_BASE_URL } from '../../env'
import { reportError } from '../../lib/raygun'

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required('Please enter your first name'),
    lastName: yup.string().required('Please enter your last name'),
    dob: yup.string().required('Please enter your date of birth'),
    type: yup
      .string()
      .oneOf(['MB', 'AP', 'ST', 'n/a'], 'Please select an option')
      .required(),
    pgaId: yup.string().when('type', {
      is: val => ['MB', 'AP', 'ST'].includes(val),
      then: () => yup.string().required('Please enter your PGA ID'),
      otherwise: () => yup.string().optional()
    }),
    sectionId: yup.string().when('type', {
      is: val => ['MB', 'AP'].includes(val),
      then: () =>
        yup.string().oneOf(
          sections.map(s => s.id),
          'Please select your section'
        ),
      otherwise: () => yup.string().optional()
    })
  })
  .required()

const ForgotEmailForm = () => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: '',
      lastName: '',
      dob: '',
      type: '',
      pgaId: '',
      sectionId: ''
    }
  })

  const onSubmit = async data => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/auth/forgot-email`,
        {
          first_name: data.firstName.trim(),
          last_name: data.lastName.trim(),
          dob: data.dob,
          type: data.type,
          pga_id: data.type === 'n/a' ? null : data.pgaId,
          section_id:
            data.type === 'n/a' || data.type === 'ST' ? null : data.sectionId
        },
        { validateStatus: status => status === 200 || status === 404 }
      )

      const state = new URLSearchParams(window.location.search).get('state')

      if (response.status === 404) {
        const params = new URLSearchParams({
          supportEmail: response.data.support_email,
          state
        })
        window.location.href = `/account-not-found?${params.toString()}`
      } else {
        const params = new URLSearchParams({
          loginEmail: response.data.login_email,
          supportEmail: response.data.support_email,
          state
        })
        window.location.href = `/found-account?${params.toString()}`
      }
    } catch (error) {
      reportError(error)
    }
  }

  const type = watch('type')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mb={2}>
        <TextField
          {...register('firstName')}
          variant='outlined'
          label='First Name *'
          error={!!errors.firstName}
          helperText={errors.firstName?.message}
        />
      </Box>
      <Box mb={2}>
        <TextField
          {...register('lastName')}
          variant='outlined'
          label='Last Name *'
          error={!!errors.lastName}
          helperText={errors.lastName?.message}
        />
      </Box>
      <Box mb={2}>
        <TextField
          {...register('dob')}
          label='Date of Birth *'
          InputLabelProps={{ shrink: true }}
          type='date'
          error={!!errors.dob}
          helperText={errors.dob?.message}
        />
      </Box>
      <Box mb={2}>
        <FormControl component='fieldset' error={!!errors.type}>
          <FormLabel variant='body1'>I am a...</FormLabel>
          <Controller
            name='type'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <RadioGroup row aria-label='type' {...field}>
                <FormControlLabel
                  value='MB'
                  control={<Radio />}
                  label='PGA Member'
                />
                <FormControlLabel
                  value='AP'
                  control={<Radio />}
                  label='PGA Associate'
                />
                <FormControlLabel
                  value='ST'
                  control={<Radio />}
                  label='PGM University Student'
                />
                <FormControlLabel
                  value='n/a'
                  control={<Radio />}
                  label='Other'
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        {errors.type && (
          <FormHelperText error>{errors.type.message}</FormHelperText>
        )}
      </Box>
      {['MB', 'AP', 'ST'].includes(type) && (
        <Box mb={2}>
          <TextField
            {...register('pgaId')}
            variant='outlined'
            label='PGA ID *'
            inputProps={{ maxLength: 8 }}
            error={!!errors.pgaId}
            helperText={errors.pgaId?.message}
          />
        </Box>
      )}
      {['MB', 'AP'].includes(type) && (
        <>
          <Box mb={2}>
            <FormControl>
              <InputLabel>Section *</InputLabel>
              <Select
                {...register('sectionId')}
                variant='outlined'
                label='Section *'
                error={!!errors.section}
              >
                {sections.map(section => (
                  <MenuItem key={section.id} value={section.id}>
                    {section.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.section && (
                <FormHelperText error>{errors.section.message}</FormHelperText>
              )}
            </FormControl>
          </Box>
        </>
      )}
      <Button
        type='submit'
        color='primary'
        variant='contained'
        size='large'
        fullWidth
      >
        Submit
      </Button>
    </form>
  )
}

export default ForgotEmailForm

import React from 'react'
import { PageLayout, links } from '../../lib/auth0'
import { useQueryStringParameters } from '../../lib/query-string'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const AccountNotFoundPage = () => {
  const { supportEmail = 'support@pga.com' } = useQueryStringParameters()
  return (
    <PageLayout>
      <Typography variant='h1'>Account Not Found</Typography>
      <Box mb={2}>
        <Typography variant='body1'>
          We were unable to find an account that matches the information
          provided. Please <Link href={links.forgotEmail()}>try again</Link> or
          reach out to{' '}
          <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for help.
        </Typography>
      </Box>
      <Box textAlign='center'>
        <Link href={links.resumeLogin()}>Back to login</Link>
      </Box>
    </PageLayout>
  )
}

export default AccountNotFoundPage

export const sections = [
  { id: '005', name: 'Aloha Section' },
  { id: '010', name: 'Carolinas Section' },
  { id: '015', name: 'Central New York Section' },
  { id: '020', name: 'Colorado Section' },
  { id: '025', name: 'Connecticut Section' },
  { id: '030', name: 'Alabama - NW Florida Section' },
  { id: '041', name: 'Gateway Section' },
  { id: '043', name: 'Georgia Section' },
  { id: '045', name: 'Gulf States Section' },
  { id: '050', name: 'Illinois Section' },
  { id: '055', name: 'Indiana Section' },
  { id: '060', name: 'Iowa Section' },
  { id: '065', name: 'Kentucky Section' },
  { id: '075', name: 'Metropolitan Section' },
  { id: '080', name: 'Michigan Section' },
  { id: '085', name: 'Middle Atlantic Section' },
  { id: '090', name: 'Midwest Section' },
  { id: '095', name: 'Minnesota Section' },
  { id: '100', name: 'Nebraska Section' },
  { id: '105', name: 'New England Section' },
  { id: '110', name: 'New Jersey Section' },
  { id: '115', name: 'Northeastern New York Section' },
  { id: '120', name: 'Northern California Section' },
  { id: '125', name: 'Northern Ohio Section' },
  { id: '130', name: 'Northern Texas Section' },
  { id: '135', name: 'North Florida Section' },
  { id: '140', name: 'Pacific Northwest Section' },
  { id: '145', name: 'Philadelphia Section' },
  { id: '150', name: 'Rocky Mountain Section' },
  { id: '155', name: 'South Central Section' },
  { id: '170', name: 'Southern California Section' },
  { id: '180', name: 'Southern Ohio Section' },
  { id: '190', name: 'Southern Texas Section' },
  { id: '195', name: 'South Florida Section' },
  { id: '200', name: 'Southwest Section' },
  { id: '201', name: 'Sun Country Section' },
  { id: '203', name: 'Tennessee Section' },
  { id: '205', name: 'Tri-State Section' },
  { id: '210', name: 'Utah Section' },
  { id: '220', name: 'Western New York Section' },
  { id: '230', name: 'Wisconsin Section' },
  { id: '290', name: 'International Section' }
]

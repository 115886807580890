import React from 'react'
import { PageLayout, links } from '../../lib/auth0'
import ForgotEmailForm from './ForgotEmailForm'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import * as amplitude from '@amplitude/analytics-browser'

const ForgotEmailPage = () => {
  return (
    <PageLayout>
      <Typography variant='h1'>Recover My Account</Typography>
      <Box mb={2}>
        <Typography variant='body1'>
          Enter your information below to locate your account.
        </Typography>
      </Box>
      <Box mb={2}>
        <ForgotEmailForm />
      </Box>
      <Box textAlign='center'>
        <Link
          onClick={() => amplitude.track('click-back-to-login')}
          href={links.resumeLogin()}
        >
          Back to login
        </Link>
      </Box>
    </PageLayout>
  )
}

export default ForgotEmailPage

import React from 'react'
import { PageLayout, links } from '../../lib/auth0'
import { useQueryStringParameters } from '../../lib/query-string'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const FoundAccountPage = () => {
  const {
    loginEmail,
    supportEmail = 'support@pga.com'
  } = useQueryStringParameters()
  return (
    <PageLayout>
      <Typography variant='h1'>Account Found</Typography>
      <Box mb={2}>
        <Typography variant='body1'>
          Great news! We found an account at <strong>{loginEmail}</strong> that
          matches the information provided.
        </Typography>
      </Box>
      <Typography variant='body1'>
        Please select from one of the following options to continue recovering
        your account:
      </Typography>
      <ul>
        <Typography component='li' variant='body1'>
          <Link href={links.resumeLogin(loginEmail)}>Log in</Link>
        </Typography>
        <Typography component='li' variant='body1'>
          <Link href={links.passwordResetStart(loginEmail)}>
            Reset your password
          </Link>
        </Typography>
      </ul>
      <Typography variant='body1'>
        If you no longer have access to this email address, reach out to{' '}
        <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for help.
        You may be asked to provide additional information to verify your
        identity.
      </Typography>
    </PageLayout>
  )
}

export default FoundAccountPage

import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import AccountNotFoundPage from './pages/account-not-found'
import ForgotEmailPage from './pages/forgot-email'
import FoundAccountPage from './pages/found-account'
import VerifyEmailPage from './pages/verify-email'

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path='/account-not-found'
          component={AccountNotFoundPage}
        />
        <Route exact path='/forgot-email' component={ForgotEmailPage} />
        <Route exact path='/found-account' component={FoundAccountPage} />
        <Route exact path='/verify-email' component={VerifyEmailPage} />
        <Route
          path='*'
          render={() => (
            <Box my={4} textAlign='center'>
              Page Not Found
            </Box>
          )}
        />
      </Switch>
    </Router>
  )
}

export default Routes

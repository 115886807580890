import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

const theme = createTheme({
  typography: {
    fontFamily:
      "'ULP Custom', -apple-system, BlinkMacSystemFont, Roboto, Helvetica, sans-serif",
    h1: {
      fontSize: '24px',
      fontWeight: '400',
      textAlign: 'center',
      margin: '24px 0 16px'
    },
    body1: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '1.5'
    }
  },
  palette: {
    primary: {
      main: '#004CEC',
      constrastText: '#FFFFFF',
      selected: 'rgba(0, 35, 75, 0.08)'
    },
    secondary: {
      main: '#004CEC',
      contrastText: '#FFFFFF'
    },
    success: {
      main: '#4CAF50',
      light: '#7BC67E',
      dark: '#3B873E',
      contrastText: '#FFFFFF'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.64)',
      disabled: 'rgba(0, 0, 0, 0.38)'
    },
    action: {
      hoverOpacity: 0.04,
      selectedOpacity: 0.08
    },
    divider: '#E0E0E0',
    background: {
      default: 'rgb(245, 245, 245)'
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true
    },
    MuiFormControl: {
      variant: 'outlined',
      fullWidth: true
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: '50px'
      }
    },
    MuiLink: {
      root: {
        fontWeight: 600,
        textDecoration: 'none !important'
      }
    }
  }
})

export default responsiveFontSizes(theme)

import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { PageLayout, links } from '../../lib/auth0'
import { useQueryStringParameters } from '../../lib/query-string'

const VerifyEmailPage = () => {
  const {
    warning,
    supportEmail = 'support@pga.com'
  } = useQueryStringParameters()
  return (
    <PageLayout>
      <Typography variant='h1'>Confirm Your Email</Typography>
      <Box mb={4}>
        <Typography variant='body1'>
          Almost there! Please click on the link we sent to your email to move
          forward.
          <br />
          <br />
          Can&apos;t find the email? No worries, just reach out to{' '}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a> for help.
        </Typography>
      </Box>
      <Button
        variant='contained'
        color='primary'
        size='large'
        href={links.continueLogin()}
      >
        Continue
      </Button>
      {warning && (
        <p style={{ color: '#D32F2F', margin: '8px 0 0' }}>{warning}</p>
      )}
    </PageLayout>
  )
}

export default VerifyEmailPage

import React from 'react'
import ReactDOM from 'react-dom'
import fromEntries from 'object.fromentries'
import 'url-search-params-polyfill'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
import Routes from './routes'
import reportWebVitals from './reportWebVitals'
import * as Amplitude from '@amplitude/analytics-browser'
import { AMPLITUDE_API_KEY } from './env'

if (!Object.fromEntries) {
  fromEntries.shim()
}

const App = () => {
  Amplitude.init(AMPLITUDE_API_KEY, {
    // This allows Amplitude to send events asynchronously, including after a domain redirect.
    transport: 'beacon',
    // To make sure the event will be scheduled right away.
    flushIntervalMillis: 0,
    flushQueueSize: 1,
    // Prevent unneeded tracking.
    defaultTracking: {
      attribution: false,
      pageViews: false,
      sessions: false,
      formInteractions: false,
      fileDownloads: false
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  )
}

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

render()

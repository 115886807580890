import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(_ => ({
  root: {
    minHeight: '100vh',
    height: 'fit-content',
    backgroundColor: '#000',
    backgroundImage:
      "url('https://images.ctfassets.net/56u5qdsjym8c/3MODyhNak3B1FiX6QZ1k1m/60c76e7f493e787614aef34f0eea1639/auth0-background.jpeg')",
    backgroundPosition: '50%',
    backgroundSize: 'cover'
  },
  widget: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minHeight: '100vh',
    padding: '80px',

    '@media (max-width: 600px)': {
      width: 'auto',
      padding: '0',
      margin: '0'
    }
  },
  prompt: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    whiteSpace: 'normal',
    fontSize: '16px',
    color: '#111111',
    backgroundColor: '#fff',
    boxShadow: '0 12px 40px rgba(0,0,0,.12)',
    borderRadius: '50px',
    border: '0px solid #BFC8CC',
    width: '400px',

    '@media (max-width: 600px)': {
      width: '100%',
      height: '100vh',
      borderRadius: '0',
      boxShadow: 'none'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '540px',
    padding: '40px',

    '@media (max-width: 600px)': {
      justifyContent: 'flex-start'
    },

    '& a': {
      textDecoration: 'none'
    },

    '& button': {
      backgroundColor: '#004CEC !important',
      textDecoration: 'none !important',

      '&:hover': {
        backgroundColor: '#004CEC !important',
        boxShadow: 'none !important'
      }
    }
  }
}))

const PageLayout = props => {
  const styles = useStyles()
  return (
    <Box className={styles.root}>
      <Box className={styles.widget}>
        <Box className={styles.prompt}>
          <Box className={styles.content}>{props.children}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PageLayout
